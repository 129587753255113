import React from 'react'
import '../../App.css'
import Typewriter from 'typewriter-effect'
import './HeroSection.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLinkedinIn, faDribbble, faGithub} from '@fortawesome/free-brands-svg-icons'
import { Button } from '../Button'

function HeroSection() {
    return (
        <div className='hero-container'>
            <Typewriter
                options={{
                strings: ['UX/UI Designer', 'Front-end Developer', 'Photographer'],
                autoStart: true,
                loop: true,
                }}
            />
            <h1>Brianna Martin</h1>
            <p>
            Hey ! My name is Brianna Martin and I’m a Austin based UX/UI designer and front-end 
            developer with over 4 years of experience. When I am not coding or designing I 
            enjoy taking pictures with my Canon camera. 
            </p>
            <div className="icons">
                <a className="icon-holder" href="https://www.linkedin.com/in/brianna-martin-dev/" target="_blank">
                    <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
                </a>
                <a className="icon-holder" href="https://dribbble.com/nobodybut_brii" target="_blank">
                    <FontAwesomeIcon icon={faDribbble} className="icon" />
                </a>
                <a className="icon-holder" href="https://github.com/bmar0818" target="_blank">
                    <FontAwesomeIcon icon={faGithub} className="icon" />
                </a>
            </div>
            <div className='hero-btn'>
                <Button 
                    className='btn'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                    View Portfolio
                </Button>
            </div>
        </div>
    )
}

export default HeroSection
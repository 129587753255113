import React from 'react'
import './AboutSkills.css'

function AboutSkills({skill}) {
    return (
        <div className="AboutSkills">
            <div className="square"></div>
            <h3>{skill}</h3>
        </div>
    )
}

export default AboutSkills

import React from 'react'
import Title from '../Title/Title'
import AboutSkills from '../AboutSkills/AboutSkills'
import './AboutSection.css'
import Timeline from '../Timeline/Timeline'
import '../../App.css'


function AboutSection() {
    return (
        <div className='About'id='about'> 
            <div className="container">
                <Title title={'About Me'} span={'About Me'} />
                <div className="about-flex">
                    <div className="image">
                        <img src='../img/bri-2.jpg'/>
                    </div>
                    <div className="about-content">
                        <p>
                            I am techie who loves designing graphics, devolping websites and I am pretty good with 
                            snapping pics with my Canon camera. Tech has always been a big part of my life since I
                            was a child. I remember gettingmy first desktop computer and for fun, learning how 
                            to take it apart and put it back together. It wasn’t until high school that I realized that this was something 
                            I wanted to spend the rest of my life doing. I then attended Florida Memorial University(the 
                            only HBCU in South Florida) and graduated with a Bachelors in Computer Information Systems.
                            After college I started doing freelance work to get my name and my skills out there in the world.
                            I started out creating and developing website, then moved on to graphics and now recently photography.
                            I like to think of myself as a jack of all trades or simply a creative who is simply passionate 
                            about multiple things. 
                        </p>
                        <h2 style={{fontFamily:'Sacramento', fontWeight:'lighter'}}>-Brianna Martin</h2>
                        <div className="skills">
                        <AboutSkills skill={'Graphic Design/Product Design'} />
                        <AboutSkills skill={'UI/UX Design'} />
                        <AboutSkills skill={'Photography'} />
                        <AboutSkills skill={'Web Development'} />
                        </div>
                    </div>
                </div>
                <Timeline />
            </div>
        </ div>
    )
}

export default AboutSection
import React, { useState, useEffect  } from 'react'
import { Link as LinkR } from 'react-router-dom'
import {Link as LinkS, animateScroll as scroll} from 'react-scroll'
import { Button } from "../Button"
import './Navbar.css'


function Navbar() {
    const [clicked, setClick] = useState(false)
    const toggleHome = () => {
        scroll.scrollToTop()
    }
    const handleClick = () => setClick(!clicked)
    const closeMobileMobile = () => setClick(false)
    return (
        <>
            <nav className="NavbarItems">
                <div className="navbar-container">
                    <LinkR className="navbar-logo" to='/' onClick={toggleHome}>Brianna Martin</LinkR>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={clicked? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <LinkS to='/' className='nav-links' onClick={closeMobileMobile, toggleHome} exact='true' activeClass='active'>
                                Home
                            </LinkS>
                        </li>
                        <li className='nav-item'>
                            <LinkS to='about' className='nav-links' onClick={closeMobileMobile} smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>
                                About
                            </LinkS>
                        </li> 
                        <li className='nav-item'>
                            <LinkS to='portfolio' className='nav-links' onClick={closeMobileMobile} smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass='active'>
                                Portfolio
                            </LinkS>
                        </li>  
                        <li className='nav-item'>
                            <LinkS to='contact'  className='nav-links' onClick={closeMobileMobile} smooth={true} duration={500} spy={true} exact='true' offset={-400} activeClass='active'>
                                Contact
                            </LinkS>
                        </li>                     
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar

import port1 from '../../img/portImages/port1.png';
import port2 from '../../img/portImages/port2.png';
import port3 from '../../img/portImages/port3.png';
import port4 from '../../img/portImages/port4.png';
import port5 from '../../img/portImages/port5.png';
import port6 from '../../img/portImages/port6.png';
import port7 from '../../img/portImages/port7.png';
import port8 from '../../img/portImages/port8.png';
import port9 from '../../img/portImages/port9.png';
import port10 from '../../img/portImages/port10.png';
import port11 from '../../img/portImages/port11.png';
import port12 from '../../img/portImages/port12.jpg';
import port13 from '../../img/portImages/port13.png';
import port14 from '../../img/portImages/port14.png';
import port15 from '../../img/portImages/port15.jpg';
import port16 from '../../img/portImages/port16.jpg';
import port17 from '../../img/portImages/port17.jpg';
import port18 from '../../img/portImages/port18.jpg';
import port19 from '../../img/portImages/port19.png';
import port20 from '../../img/portImages/port20.png';

const Work = [
    {
        id: 1,
        category: 'Graphic Design',
        image: port1,
        title: 'Real Is Rare Business Card Design',
        desc: 'Logo created using Adobe Illustrator and business card design using Adobe Photoshop',
        link: 'https://dribbble.com/shots/15187969-Real-is-Rare-Business-Card-Design'
    },
    {
        id: 2,
        category: 'UI/UX Design',
        image: port2,
        title: 'Roar Bikes Website Design',
        desc: 'A website design prototype created for a bike company. Created using Adobe XD and Photoshp.',
        link: 'https://xd.adobe.com/view/534c371f-3e42-4a03-a434-2cb5822c0af6-061f/'
    },
    {
        id: 3,
        category: 'React Js',
        image: port3,
        title: 'Chat Application',
        desc: 'A React chat application with the ability to send messages and media, create multiple rooms, and online status.',
        link: 'https://bri-chat-app.netlify.app/'
    },
    {
        id: 5,
        category: 'React Js',
        image: port5,
        title: 'Movie App',
        desc: 'A React movie app to display/search recent movies,their overview and rating score.',
        link: 'https://bri-movie-app.netlify.app/'
    },
    {
        id: 6,
        category: 'Graphic Design',
        image: port6,
        title: 'Discover Brochure Design',
        desc: 'A travel brochure design using Adobe InDesign.',
        link: 'https://dribbble.com/shots/15188058-Brochure-Design'
    },
    {
        id: 7,
        category: 'Graphic Design',
        image: port7,
        title: 'Fashion Dinner Flyer',
        desc: 'A flyer design where food and fashion collide. Created with Adobe Photoshop.',
        link:'https://dribbble.com/shots/15188629-Fashion-Dinner-Flyer'
    },
    {
        id: 8,
        category: 'Wordpress',
        image: port8,
        title: 'PRMTD Entertainment Website',
        desc: 'A custom Wordpress theme created from scratch for a music enterntainment branding company written in PHP, Javascript and CSS.',
        link:'https://prmtdentertainment.com'
    },
    {
        id: 9,
        category: 'UI/UX Design',
        image: port9,
        title: 'Maynooth Website Design',
        desc: 'A website design prototype created for a furniture company. Created using Adobe XD and Photoshp.',
        link:'https://xd.adobe.com/view/09980af0-28da-4338-814a-4beff31ec66a-5ccb/'
    },
    {
        id: 10,
        category: 'UI/UX Design',
        image: port10,
        title: 'Music App Design',
        desc: 'A music playist application design. Created using Adobe XD and Photoshp.',
        link: 'https://dribbble.com/shots/10481865-Daily-UI-006'
    },
    {
        id: 11,
        category: 'React Js',
        image: port11,
        title: 'ToDo List Application',
        desc: 'A React to-do list appliction.',
        link: 'https://bri-todo-app.netlify.app/'
    },
    {
        id: 12,
        category: 'UI/UX Design',
        image: port12,
        title: 'Fitness Login Design',
        desc: 'A fitness signup page design. Created with Adobe XD.',
        link: 'https://dribbble.com/shots/10037596-DailyUI001'
    },
    {
        id: 13,
        category: 'React Js',
        image: port13,
        title: 'COVID-19 Tracker',
        desc: 'A React COVID-19 Tracker that displays total cases, deaths,recovered cases and also live cases by country.',
        link: 'https://bri-covid-19-tracker.netlify.app/'
    },
    {
        id: 15,
        category: 'UI/UX Design',
        image: port15,
        title: '404 Design',
        desc: 'A 404 page design created with Adobe XD.',
        link: 'https://dribbble.com/shots/10878864-Daily-UI-008'
    },
    {
        id: 16,
        category: 'Graphic Design',
        image: port16,
        title: 'App Icon Design',
        desc: 'An app icon design created with Adobe Illustrator.',
        link: 'https://dribbble.com/shots/10427314-Daily-UI-005'
    },
    {
        id: 17,
        category: 'UI/UX Design',
        image: port17,
        title: 'OVO Festival Homepage Design',
        desc: 'A music festival homepage design created with Adobe XD',
        link: 'https://dribbble.com/shots/10162796-Daily-UI-003'
    },
    {
        id: 18,
        category: 'UI/UX Design',
        image: port18,
        title: 'Tip Calculator App Design',
        desc: 'A tip calcutator design created with Adobe XD.',
        link: 'https://dribbble.com/shots/10414893-Daily-UI-004',
    },
    {
        id: 19,
        category: 'Graphic Design',
        image: port19,
        title: 'All In Shirt Design',
        desc: 'A football team t-shirt design created with Adobe Illustrator and Photoshop.',
        link: 'https://dribbble.com/shots/15188782-All-In-T-Shirt-Design'
    },
    {
        id: 20,
        category: 'Graphic Design',
        image: port20,
        title: 'Food & Wine Festival Flyer Design',
        desc: 'A flyer design for a food and wine festival. Created with Adobe Photoshop',
        link: 'https://dribbble.com/shots/15188805-Food-Wine-Festival-Flyer-Design'
    }
]

export default Work;
import React, {useState} from 'react'
import './AllPortfolio'

function MenuItems({menuItem}) {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(prev => !prev)
    }
    return (
        <div className='portfolio-menu'>
            {
                menuItem.map((item) => {
                    return <div className="portfolio-item" key={item.id} >
                        <div className="image-container">
                            <img src={item.image} alt="" onClick={openModal}/>
                            <div className="overlay">
                                <div className="text">
                                    <h4>{item.title}</h4>
                                    <p>{item.desc}</p>
                                    <a href={item.link} target="_blank">View Project</a>
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
            
        </div>
    )
}

export default MenuItems

let TimelineElements = [
    {
        id: 1,
        title: "SENIOR SOFTWARE DEVELOPER",
        company: "Powerhouse Group Enterprise",
        location: "Austin, TX",
        description: "Leading development team projects and creating a company Customer Relationship Management(CRM) application. Migrating data from LightSpeed CRM to company’s CRM. Clearly and regularly communicate with management and technical support colleagues. Design and update software database. Including software applications, web sites, user interfaces and user experience. Develop, test and implement new software programs.",
        date: "June 2020 - September 2020",
        icon: "work"
    },
    {
        id: 2,
        title: "UI/UX DESIGNER & FRONT-END DEVELOPER",
        company: "Freelance",
        location: "Miami, FL",
        description: "Clients: Get After It Now, Beauty by Bell, Healing Hands Medi Spa, Hair Physician, BCreative GPX, Miami Entertainment Awards, All Money Invested Associates. Developing wireframes and task flows based on user needs. Work with organizations, developing websites that include e-commerce,booking, portfolio, blog and multilingual functionalities. Skills Used: Photoshop, XD, Illustrator, InDesign, HTML, CSS, ReactJS, PHP, Javascript.",
        date: "August 2019 - June 2020",
        icon: "work"
    },
    {
        id: 3,
        title: "BLACK AGENDA FELLOWSHIP",
        company: "Code Fever Miami",
        location: "Miami, FL",
        description: "Work closely with Code Fever’s Leadership and Management Team to develop graphics and websites. Developed 20+ graphic design projects. Outline Intercity Games app goals, illustrate wire frame, and site functionality. Coordinate Intercity Games, connecting youth summer camps from different social and economic backgrounds through team cohorts with each other using video conferencing and digital games found on the Intercity GamesApp. Skills Used: HTML, CSS, Illustrator, ReactJS, Photoshop, InDesign.",
        date: "June 2019 - August 2019",
        icon: "work"
    },
    {
        id: 4,
        title: "GRAPHIC DESIGNER/UI & UX DESIGNER",
        company: "Highly Unique Radio",
        location: "Atlanta, GA",
        description: " Successfully manage and coordinate graphic design projects from conceptthrough completion. Create wireframes and designs for company’s website and app. Work closely with clients to create vision, conceive designs, and consistently meet deadlines and requirements.",
        date: "December 2018 - June 2019",
        icon: "work"
    }
    
]

export default TimelineElements
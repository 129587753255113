import React from 'react'
import {Link} from 'react-router-dom'
import Title from '../Title/Title'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLinkedinIn, faDribbble, faGithub} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import '../Contact/Contact.css'


function Contact() {
    return (
        <div className='Contact' id='contact'>
            <div className="contact-content">
                <div className="container">
                    <Title title={'Get in Touch'} span={'Get in Touch'} />
                    <div class='contact-text'>
                        <p>Let's connect! I am currently looking for new opputunities. My inbox is always open. <br/> Whether you a question, just want to say hi, or want to network. Please do not hesitate to contact me.</p>
                    </div> 
                    <div className="icons">
                        <a className="icon-holder" href="https://www.linkedin.com/in/brianna-martin-dev/" target="_blank">
                            <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
                        </a>
                        <a className="icon-holder" href="https://dribbble.com/nobodybut_brii" target="_blank">
                            <FontAwesomeIcon icon={faDribbble} className="icon" />
                        </a>
                        <a className="icon-holder" href="https://github.com/bmar0818" target="_blank">
                            <FontAwesomeIcon icon={faGithub} className="icon" />
                        </a>
                        <a className="icon-holder" href="mailto:briannamartin0818@gmail.com">
                            <FontAwesomeIcon icon={faEnvelope} className="icon"/>
                        </a>
                    </div>
                    <button type="button" className="btn-port"><a href="mailto:briannamartin0818@gmail.com">Say Hello</a></button>
                </div>
                <div className="contact-bg">
                </div>
            </div>
        </div>
    )
}

export default Contact

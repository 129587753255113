import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSection/HeroSection'
import AboutSection from '../AboutSection/AboutSection'
import Portfolio from '../Portfolio/Portfolio'
import Contact from '../Contact/Contact'

function Home() {
    return (
      <>
        <HeroSection />
        <AboutSection />
        <Portfolio />
        <Contact />
      </>
    );
  }

export default Home
import React, { useState } from 'react'
import Title from '../Title/Title'
import Categories from './Categories'
import MenuItems from './MenuItems'
import Work from './AllPortfolio'
import './Portfolio.css'

const allCategories = ['All', ...new Set(Work.map(item => item.category))];

function Portfolio() {
    const [categories, setCategories] = useState(allCategories)
    const [menuItems, setMenuItems] = useState(Work)

    const filter = (category) => {
        if(category === 'All') {
            setMenuItems(Work)
            return
        }
        const filteredData  = Work.filter((item)=> {
            return item.category === category;
        })
        setMenuItems(filteredData);
    }

    return (
        <div className='Portfolio' id='portfolio'>
            <div className="container">
                <Title title={'Portfolio'} span={'Portfolio'} categories={categories} />
                <div className="work">
                    <Categories filter={filter} categories={categories} />
                    <MenuItems menuItem={menuItems} />
                </div>
            </div>
        </div>
    )
}

export default Portfolio

import React from 'react'
import {ReactComponent as WorkIcon} from '../../img/work-2.svg'
import TimelineElements from './TimelineElements'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css';

function Timeline() {
    let workIconStyles ={
        background: "#c47230",
        webkitBoxShadow: "0 0 0 4px #E8A167, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)",
        boxShadow: "0 0 0 4px #E8A167, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%)"
    };
    return (
        <div className="Timeline">
            <VerticalTimeline>
                {
                    TimelineElements.map(element => {

                        return (
                            <VerticalTimelineElement 
                                key={element.key}
                                date={element.date}
                                dateClassName="date"
                                iconStyle={workIconStyles}
                                icon={<WorkIcon />}
                                contentStyle={{borderTop: "6px solid #E8A167"}}
                            >
                                <h3 className="vertical-timeline-element-title">
                                    {element.title}
                                </h3>
                                <h5 className="vertical-timeline-element-subtitle">
                                    {element.company} | {element.location}
                                </h5>
                                <p id="description">{element.description}</p>
                            </VerticalTimelineElement>
                        )
                    })
                }
            </VerticalTimeline>
        </div>
    )
}

export default Timeline
